import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card, Tab, Tabs } from "@themesberg/react-bootstrap";
import AllBuyTradesTable from "../../components/Tables/AllBuyTradesTable";
import AllSellTradesTable from "../../components/Tables/AllSellTradesTable";
import Meta from "../../components/Meta";
import { admin_all_trades_request } from "../../redux/actions/tradesAction";
import Preloader from "../../components/Preloader";
import "./styles.css";

const AdminTradeRequests = () => {
  const [status, setStatus] = React.useState("");
  const [query, setQuery] = React.useState("");
  const dispatch = useDispatch();

  const allTrades = useSelector((state) => state.allTrades);
  const { loading, adminViewTrades } = allTrades;

  const getAllBuyOrders = () => {
    const resultArray = [];

    if (adminViewTrades && adminViewTrades.length > 0) {
      const filterData = adminViewTrades.filter(
        (buy) => buy.orderType === "BUY"
      );

      filterData.forEach((obj) => {
        const newObj = {
          id: obj._id || 0,
          createdAt: obj.createdAt || 0,
          accountNo: obj.accountNo,
          clientName: obj.clientName,
          brokerCode: obj.brokerCode,
          security: obj.security,
          units: obj.units,
          basePrice: obj.basePrice,
          costPrice: obj.costPrice,
          nasdFee: obj.nasdFee,
          nasdFeeVat: obj.nasdFeeVat,
          secFee: obj.secFee,
          xAlert: obj.xAlert,
          xAlertVat: obj.xAlertVat,
          brokerCommission: obj.brokerCommission,
          brokerCommissionVat: obj.brokerCommissionVat,
          stampDuties: obj.stampDuties,
          totalTradeCost:
            obj.costPrice +
            obj.nasdFee +
            obj.nasdFeeVat +
            obj.secFee +
            obj.xAlert +
            obj.xAlertVat +
            obj.brokerCommission +
            obj.brokerCommissionVat +
            obj.stampDuties,
          paymentChannel: obj.paymentChannel,
          paystacktransferFee: obj.paystacktransferFee,
          paystackServiceCharge: obj.paystackServiceCharge,
          finalTotal: obj.finalTotal,
          status: obj.status,
        };

        resultArray.push(newObj);
      });
    }

    return resultArray;
  };

  const getAllSellOrders = () => {
    if (adminViewTrades && adminViewTrades.length > 0) {
      return adminViewTrades.filter((buy) => buy.orderType === "SELL");
    }
  };

  React.useEffect(() => {
    // if (query && query.length >= 4) {
    dispatch(
      admin_all_trades_request({
        status: status,
        search: query,
      })
    );
    // }
  }, [status, query]);

  return (
    <>
      <Meta title="All Trade Requests For A Broker" />
      <section className="section">
        <div className="container-fluid">
          <div className="title-wrapper pt-30">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="title mb-30">
                  <h2>All Trade Requests</h2>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>

          <div className="search_container">
            <div className="filter">
              <span>Filter by Trade Status</span>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value={""}>All</option>
                <option value={"EXPIRED"}>Expired</option>
                <option value={"MATCHED"}>Matched</option>
                <option value={"FAILED"}>Failed</option>
                <option value={"CANCELED"}>Canceled</option>
              </select>
            </div>
            <div className="search">
              <span>Search by Client Name or Account Number</span>
              <input
                type="search"
                placeholder="Search by name or account number"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>

          <Tabs defaultActiveKey="buy" className="mb-3">
            <Tab eventKey="buy" title="Buy Orders">
              {loading ? (
                <Preloader />
              ) : (
                <Row>
                  <Col lg={12}>
                    <Card border="light" className="p-0 mb-4">
                      <Card.Body className="pb-5">
                        {adminViewTrades && adminViewTrades.length > 0 && (
                          <AllBuyTradesTable entries={getAllBuyOrders()} />
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </Tab>
            <Tab eventKey="sell" title="Sell Orders">
              {loading ? (
                <Preloader />
              ) : (
                <Row>
                  <Col lg={12}>
                    <Card border="light" className="p-0 mb-4">
                      <Card.Body className="pb-5">
                        {adminViewTrades && adminViewTrades.length > 0 && (
                          <AllSellTradesTable entries={getAllSellOrders()} />
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </Tab>
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default AdminTradeRequests;
